import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalContext } from '../contexts/Modals';

const ModalContainer = () => {
  const { modalContent, handleModal, modal, size } = useContext(ModalContext);

  return (
    <Modal size={size} show={modal} onHide={() => handleModal(false)}>
      {modalContent}
    </Modal>
  );
};

export default ModalContainer;
