// @flow
import React from 'react';
import classNames from 'classnames';

/**
 * Spinner
 */
const Loader = (props) => {
  const children = props.children || null;
  const Tag = props.tag || 'div';
  const color = props.color || 'secondary';
  const size = props.size || '';

  return (
    <div className="d-flex vh-100 justify-content-center align-items-center">
      <Tag
        role="status"
        className={classNames(
          {
            'spinner-border': props.type === 'bordered',
            'spinner-grow': props.type === 'grow'
          },
          [`text-${color}`],
          { [`avatar-${size}`]: size },
          props.className
        )}
      >
        {children}
      </Tag>
    </div>
  );
};

Loader.defaultProps = {
  tag: 'div',
  type: 'bordered'
};

export default Loader;
