import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountContext } from '../contexts/Accounts';
import getAllowedRoutes from '../core/utils/get-allowed-routes';
import PrivateRoutesConfig from '../core/config/PrivateRoutesConfig';
import MapAllowedRoutes from '../core/routes/MapAllowedRoutes';
import statusApi from '../core/services/statusApi';
import profileApi from '../core/services/profileApi';
import featureFlagsApi from '../core/services/featureFlagsApi';
import {
  updateUserBasicInfo,
  changeSalaryCheckVisibility,
  changeFreeTextSearchVisibility
} from '../redux/actions';
import { useDispatch } from 'react-redux';
import Loader from './Loader';
import { ROUTE_KEY_SALARY_CHECK, ROUTE_KEY_ACHIEVEMENTS_CHECK } from '../constants';
import { hsIdentifyUser } from '../core/utils/hubspot';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PrivateRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState();
  const [subscriptionPackage, setSubscriptionPackage] = useState();
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const { user, getSession } = useContext(AccountContext);
  const LDClient = useLDClient();
  const dispatch = useDispatch();
  const featureFlags = useFlags();
  const { showCompanyAchievements } = useFlags();

  const goToLogin = () => {
    setLoading(false);
    const fullPath = location.pathname + location.search + location.hash;
    const encodedFullPath = encodeURIComponent(fullPath === '/' ? '' : fullPath);
    history.push(`/login${encodedFullPath ? `?redirect=${encodedFullPath}` : ''}`);
  };

  const hsIdentifyCurrentUser = () => {
    try {
      if (user && user.attributes) {
        let email = user.attributes.find((attr) => attr.Name === 'email') || {};
        if (email.Value) {
          hsIdentifyUser(email.Value);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const LDContext = {
        kind: 'multi',
        user: {
          key: user.session.idToken.payload.email,
          email: user.session.idToken.payload.email,
          profileType: 'SHIPOWNER',
          profileStatus: status,
          subscriptionPackage: subscriptionPackage
        },
        device: {
          key: 'dev-1',
          platform: 'BROWSER'
        },
        application: {
          key: 'shipowner-portal',
          name: 'Shipowner Portal',
          version: '1.0.0'
        }
      };
      LDClient.identify(LDContext, null, () => {
        // console.log('feture flags available for new context');
      });
    }
  }, [user?.session, status, isLoggedIn, subscriptionPackage]);

  useEffect(() => {
    getSession()
      .then(() => {
        setIsLoggedIn(true);
        hsIdentifyCurrentUser();
      })
      .catch(() => {
        goToLogin();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);
      Promise.all([
        statusApi.getCurrentStatus({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          }
        }),
        profileApi.fetchHeaderData({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          }
        }),
        featureFlagsApi.fetchFeatureFlags({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          }
        })
      ])
        .then((res) => {
          if (res?.length > 0) {
            const statusRes = res[0];
            const headerDataRes = res[1];
            const featureFlagsRes = res[2];

            const userBasicInfo = {
              companyName: headerDataRes?.data?.companyName,
              contactPerson: headerDataRes?.data?.contactPerson,
              subscriptionPackage: headerDataRes?.data?.subscriptionPackage
            };

            dispatch(updateUserBasicInfo(userBasicInfo));
            setSubscriptionPackage(headerDataRes?.data?.subscriptionPackage);

            const flags = featureFlagsRes?.data?.flags || [];

            const salaryCheckVisibility = flags.includes('SALARY_CHECK');
            const freeTextSearchVisibility = flags.includes('FREE_TEXT_SEARCH');

            dispatch(changeSalaryCheckVisibility(salaryCheckVisibility));
            dispatch(changeFreeTextSearchVisibility(freeTextSearchVisibility));

            const status = statusRes?.data?.status;

            const exclude = [];

            if (!salaryCheckVisibility) {
              exclude.push(ROUTE_KEY_SALARY_CHECK);
            }

            if (!showCompanyAchievements) {
              exclude.push(ROUTE_KEY_ACHIEVEMENTS_CHECK);
            }

            if (status) {
              setStatus(status);
              setAllowedRoutes(
                getAllowedRoutes(PrivateRoutesConfig, status, exclude, featureFlags)
              );
            } else {
              goToLogin();
            }
          }
        })
        .catch(() => {
          goToLogin();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isLoggedIn, user.session]);

  if (loading && !isLoggedIn) {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <Loader size="lg" />
      </div>
    );
  }

  if (allowedRoutes.length) {
    return <MapAllowedRoutes routes={allowedRoutes} basePath="/" status={status} />;
  }
  return null;
};

export default PrivateRoute;
