import React, { createContext } from 'react';
import { useMediaQuery } from '../core/hooks/useMediaQuery';

const ResponsiveContext = createContext();

const ResponsiveProvider = ({ children }) => {
  let isPageWide = useMediaQuery('(min-width: 800px)');

  return (
    <ResponsiveContext.Provider value={{ isPageWide: isPageWide }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export { ResponsiveContext, ResponsiveProvider };
