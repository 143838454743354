import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/store';
import { createRoot } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useLDProvider } from 'launchdarkly-react-client-sdk'; // Ensure this import

Sentry.init({
  dsn: 'https://b9e43b711d8e40f194a61af44d36e632@o1084965.ingest.sentry.io/6162755',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_API.includes('dev')
    ? 'development'
    : 'production'
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    context: {
      kind: 'multi',
      user: {
        key: 'anonymous-user',
        name: 'anonymous',
        anonymous: true,
        profileType: 'SHIPOWNER',
        subscriptionPackage: 'default'
      },
      device: {
        key: 'dev-1',
        platform: 'BROWSER'
      },
      application: {
        key: 'shipowner-portal',
        name: 'Shipowner Portal',
        version: '1.0.0'
      }
    }
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <LDProvider>
      <Provider store={configureStore({})}>
        <App />
      </Provider>
    </LDProvider>
  );
})();
