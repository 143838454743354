import { useState } from 'react';

export default () => {
  const [modal, setModal] = useState(false);
  const [size, setSize] = useState('');
  const [modalContent, setModalContent] = useState('Not available.');

  const handleModal = (content = false) => {
    setModal(!!content);
    if (content) {
      setModalContent(content);
    } else {
      setSize('');
    }
  };

  return { modal, handleModal, modalContent, size, setSize };
};
