import api from './api';

const featureFlagsApi = (api) => {
  const endpoint = '/shipowner/flags';

  const fetchFeatureFlags = ({ headers }) => {
    const url = `${endpoint}`;
    const config = {
      headers
    };
    return api.get(url, config);
  };

  return {
    fetchFeatureFlags
  };
};

export default featureFlagsApi(api);
