import { UserActionTypes } from './constants';

const INIT_STATE = {
  userBasicInfo: {
    companyName: 'Company Portal',
    contactPerson: ''
  }
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER_BASIC_INFO: {
      return {
        ...state,
        userBasicInfo: action.payload
      };
    }
    default:
      return state;
  }
};

export default User;
