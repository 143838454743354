// @flow
import { FeatureFlagsActionTypes } from './constants';
import * as searchConstants from '../../constants/featureFlags';

const INIT_STATE = {
  searchMode: searchConstants.SEARCH_MODE_MEMORY_SEARCH,
  salaryCheckVisibility: false,
  freeTextSearchVisibility: false
};

const FeatureFlags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FeatureFlagsActionTypes.CHANGE_SEARCH_MODE:
      return {
        ...state,
        searchMode: action.payload
      };
    case FeatureFlagsActionTypes.CHANGE_SALARYCHECK_VISIBILITY:
      return {
        ...state,
        salaryCheckVisibility: action.payload
      };
    case FeatureFlagsActionTypes.CHANGE_FREE_TEXT_SEARCH_VISIBILITY:
      return {
        ...state,
        freeTextSearchVisibility: action.payload
      };
    default:
      return state;
  }
};

export default FeatureFlags;
