/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { AccountContext } from '../../contexts/Accounts';
import VerticalForm from '../../components/VerticalForm';
import FormInput from '../../components/FormInput';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import ReferralTypes from '../../core/config/ReferralTypes';

const SignUp = () => {
  const { handleSignUp } = useContext(AccountContext);
  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const tandcLabel = (
    <div className="tandc-label">
      I have read and accept the{' '}
      <a href="https://www.go-turtle.com/privacy-policy/">Privacy Policy</a> and
      accept the{' '}
      <a href="https://www.go-turtle.com/general-terms-conditions/">
        Terms and Conditions
      </a>
      . I can cancel this agreement at any time by sending a{' '}
      <a href="https://www.go-turtle.com/contact/">message</a>.
    </div>
  );

  const onSubmit = ({ email, ...values }) => {
    setLoading(true);
    handleSignUp({ ...values, email: email.toLowerCase() })
      .then(() => {
        history.push('/registration-complete');
      })
      .catch((err) => {
        setSubmitError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const schemaResolver = yupResolver(
    Yup.object().shape({
      companyName: Yup.string().required('Please enter Company name'),
      firstName: Yup.string()
        .min(2, 'Must be 2 characters or more')
        .required('Please enter First name'),
      lastName: Yup.string()
        .min(2, 'Must be 2 characters or more')
        .required('Please enter Last name'),
      email: Yup.string()
        .min(2, 'Must be 2 characters or more')
        .email('Please enter valid Email')
        .required('Please enter Email'),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])\S{6,99}$/,
          'Must contain 6 characters, one uppercase, one lowercase, one number and one special character'
        )
        .required('Please enter Password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      referralType: Yup.string().required('Required'),
      tandc: Yup.boolean()
        .required('You must agree to the terms and conditions')
        .oneOf([true], 'You must agree to the terms and conditions')
    })
  );

  const BottomLink = () => {
    return (
      <Row className="mt-3">
        <Col className="text-center">
          <p className="text-muted">
            Already have account?{' '}
            <Link to={{ pathname: '/login' }} className="text-info ms-1">
              <b>Log In</b>
            </Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-start w-100 mx-auto">
        <h1 className="display-1 text-primary text-start mt-0 fw-bold">
          Register Now
        </h1>
        <p className="text-muted text-start mb-4 w-75">
          Register your company on the TURTLE platform. After your registration a
          TURTLE representative will contact you to guide you through the next steps.
        </p>
      </div>

      {submitError && (
        <Alert variant="danger" className="my-2">
          {submitError}
        </Alert>
      )}

      <VerticalForm onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{}}>
        <FormInput
          label="Company Name"
          type="text"
          name="companyName"
          placeholder="Enter the Company name"
          containerClass="mb-3"
        />
        <FormInput
          label="First Name"
          type="text"
          name="firstName"
          placeholder="Enter your first name"
          containerClass="mb-3"
        />
        <FormInput
          label="Last Name"
          type="text"
          name="lastName"
          placeholder="Enter your last name"
          containerClass="mb-3"
        />
        <FormInput
          label="Email address"
          type="email"
          name="email"
          placeholder="Enter your email"
          containerClass="mb-3"
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          placeholder="Enter your password"
          containerClass="mb-3"
        />
        <FormInput
          label="Confirm password"
          type="password"
          name="confirmPassword"
          placeholder="Confirm your password"
          containerClass="mb-3"
        />
        <FormInput
          label="How did you hear about TURTLE?"
          type="select"
          name="referralType"
          containerClass="mb-3"
        >
          <option hidden disabled selected value="">
            Select an option
          </option>
          {ReferralTypes.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </FormInput>
        <FormInput
          label={tandcLabel}
          type="checkbox"
          name="tandc"
          containerClass="mb-3 text-muted"
        />

        <div className="mb-3 mb-0 text-center">
          <Button
            className="w-100"
            variant="danger"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />
            ) : (
              'Register'
            )}
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
};

export default SignUp;
