export const hsIdentifyUser = (userEmail) => {
  try {
    if (userEmail) {
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email: userEmail
        }
      ]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const hsTrackPageView = (pathURL) => {
  try {
    if (pathURL) {
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', pathURL]);
      _hsq.push(['trackPageView']);
    }
  } catch (error) {
    console.log(error);
  }
};
