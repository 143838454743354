const Texts = {
  GENERAL: {
    COPYRIGHT: '© 2022 Turtle GmbH',
    UPLOAD_FILE: {
      ON_SUCESS: 'File was upload successfully.',
      ON_ERROR: 'File could not be saved, please try again later.'
    }
  },
  JOB: {
    NOTIFICATIONS: {
      LOADING: 'Applying changes...',
      JOB_CREATED: 'The job was created successfully.',
      JOB_CREATED_ERROR: 'Some problems ocurred while creating the job.',
      JOB_CLOSED: 'The job was closed successfully.',
      JOB_CLOSED_ERROR: 'Some problems ocurred while closing the job.'
    },
    CONFIRMATIONS: {
      CLOSE_JOB:
        'Please confirm that you want to delete this job and all the related information.'
    }
  },
  MATCH_MATRIX: {
    NOTIFICATIONS: {
      EMBARKATION_DATE_CHANGED_SUCCESS:
        'The embarkation date has been updated successfully.',
      EMBARKATION_DATE_CHANGED_ERROR:
        'The embarkation date cannot be updated currently, please try again later.'
    }
  },
  PROFILE: {
    NOTIFICATIONS: {
      CHANGES_SAVED: 'The changes were saved successfully.',
      ERROR_SAVING: 'Changes could not be saved, please try again later.'
    }
  },
  SEAFARER_CV: {
    ERROR: 'We are having problems to generate the CV, please try later.'
  },
  SEAFARER_PROFILE: {
    HEADER_INFO_TEXT:
      'The full candidate profile, including name, picture and contact details, will be visible when the candidate has accepted a contact request.'
  },
  CANDIDATES: {
    NOTIFICATIONS: {
      SAVING: 'Saving your changes...',
      CANDIDATE_INCLUDED: 'The candidate will appear again in your searches.',
      ERROR_INCLUDING_CANDIDATE: 'Please wait a moment before trying again.'
    }
  },
  LOGIN: {
    WAITING:
      'We are preparing your upgraded TURTLE experience. This can take a few minutes, please wait...',
    WRONG_CREDENTIALS:
      'Your email or password is incorrect, please try again or reset your password through Forgot your password.',
    GENERAL_LOGIN_ERROR: 'We could not log you in, please try again later.',
    ACCOUNT_NOT_CONFIRMED:
      'Your email must be confirmed before you can login. Please check your email and click “Confirm Registration” in the last message you received. Also check your spam folder.'
  },
  SETTINGS: {
    SAVING: 'Saving your changes...',
    ERROR_SAVING: 'Changes could not be saved, please try again later.',
    CHANGES_SAVED: 'The changes were saved successfully.'
  },
  SEARCH: {
    CONFIRMATIONS: {
      DELETE_SEARCH:
        'Please confirm that you want to delete this search and all the related information.',
      SAVE_CHANGES: 'Please confirm that you want to save the changes.'
    }
  },
  SALARY_BENCHMARK: {
    GETTING_DATA: 'There was an error getting the data, please try again later.'
  },
  HISTORY_LOGS: {
    CR_SENT: 'Contact Request Sent',
    ACCEPT_CR: 'Contact Request Accepted',
    REJECT_CR: 'Contact Request Rejected',
    CANCEL_CR: 'Contact Request Cancelled',
    HIRE_SENT: 'Offer Sent',
    ACCEPT_HIRE: 'Offer Accepted',
    REJECT_HIRE: 'Offer Rejected',
    CANCEL_HIRE: 'Offer Cancelled',
    WRITE_COMMENT: 'Comment',
    VERIFICATION_REQUEST: 'Verification Request Sent',
    REJECT_CANDIDATE: 'Candidate Rejected',
    CANDIDATE_SAVED: 'Candidate Saved',
    CANDIDATE_UNSAVED: 'Candidate Unsaved',
    EXCLUDE_CANDIDATE: 'Candidate Excluded',
    INCLUDE_CANDIDATE: 'Candidate Included',
    CR_EXPIRED: 'Contact Request Expired'
  }
};

export default Texts;
