/* eslint-disable react/no-children-prop */
import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Vertical from '../../layouts/Dashboard/Vertical';

const MapAllowedRoutes = ({ routes, status }) => (
  <Vertical routes={routes} status={status}>
    <Switch>
      <Route
        exact
        path="/"
        key="default-link"
        render={() => (
          <Redirect
            to={
              routes.filter((route) => !route.isTitle).length &&
              routes.filter((route) => !route.isTitle)[0].path
            }
          />
        )}
      />
      {routes
        .filter((route) => !route.isTitle && !route.isMenuExternal)
        .map((route) => {
          const { path, component: Component, children, exact, ...rest } = route;
          return (
            <Route {...rest} key={path || 'default'} path={path} exact={exact}>
              <Component children={children} status={status} />
            </Route>
          );
        })}
    </Switch>
  </Vertical>
);

export default memo(MapAllowedRoutes);
