import React, { useContext, useState, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import VerticalForm from '../../components/VerticalForm';
import FormInput from '../../components/FormInput';
import { AccountContext } from '../../contexts/Accounts';
import Texts from '../../data/texts';
import { CognitoErrors } from '../../core/config/Constants';

import AccountLayout from '../../layouts/Authentication/AccountLayout';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { authenticate, resendConfirmationCode } = useContext(AccountContext);
  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  const schemaResolver = yupResolver(
    Yup.object().shape({
      username: Yup.string()
        .email('Please enter valid Email')
        .required('Please enter Email'),
      password: Yup.string().required('Please enter Password')
    })
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.replace('?', ''));
    const redirect = urlParams.get('redirect') || '';
    if (redirect.trim() !== '') {
      const decodedRedirect = decodeURIComponent(redirect.trim());
      setRedirectTo(decodedRedirect);
    }
  }, []);

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string()
  //       .min(2, 'Must be 2 characters or more')
  //       .required('Required'),
  //     password: Yup.string().min(6, 'Must be 6 characters or more'),
  //   }),
  //   ,
  // });

  const onSubmit = (values) => {
    setLoading(true);
    authenticate(values.username.toLowerCase(), values.password)
      .then((data) => {
        const urlParams = new URLSearchParams(location.search.replace('?', ''));
        const from = urlParams.get('from');
        if (data.newPasswordRequired) {
          history.push(`new-password?from=${from}`, {
            requiredAttributes: data.requiredAttributes
          });
        } else {
          history.push(redirectTo.trim() || '/');
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err && err.code === CognitoErrors.USER_NOT_AUTHORIZED) {
          setSubmitError(Texts.LOGIN.WRONG_CREDENTIALS);
        } else if (err && err.code === CognitoErrors.USER_NOT_CONFIRMED) {
          setSubmitError(Texts.LOGIN.ACCOUNT_NOT_CONFIRMED);
          resendConfirmationCode(values.username.toLowerCase());
        } else if (
          err &&
          err.code === CognitoErrors.INVALID_USER_PASSWORD_REQUIRED_CHANGE
        ) {
          history.push('/reset-password');
        } else {
          setSubmitError(Texts.LOGIN.WRONG_CREDENTIALS);
        }
      });
  };

  const BottomLink = useCallback(() => {
    return (
      <Row className="mt-3">
        <Col className="text-center">
          <p className="text-muted">
            Don't have an account?{' '}
            <Link to={{ pathname: '/sign-up' }} className="text-info ms-1">
              <b>Register</b>
            </Link>
          </p>
        </Col>
      </Row>
    );
  }, []);

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-start w-100 mx-auto">
        <h1 className="display-1 text-primary text-start mt-0 fw-bold">Sign In</h1>
        <p className="text-muted text-start mb-4">Sign in to the Company portal</p>
      </div>

      {submitError && (
        <Alert variant="danger" className="my-2">
          {submitError}
        </Alert>
      )}

      <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
        <FormInput
          label="Email address"
          type="text"
          name="username"
          placeholder="Enter your email"
          containerClass="mb-3"
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          placeholder="Enter your password"
          containerClass="mb-3"
        >
          <Link to="/forgot-password" className="text-muted float-end">
            <small>Forgot your password?</small>
          </Link>
        </FormInput>

        <div className="mb-3 mb-0 text-center">
          <Button
            className="w-100"
            variant="danger"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />
            ) : (
              'Log In'
            )}
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
};

export default Login;
