export const Statuses = {
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  CONFIRMED: 'CONFIRMED',
  PROFILE_REQUESTED: 'PROFILE_REQUESTED',
  REJECTED: 'REJECTED',
  REQUEST_DATA: 'REQUEST_DATA',
  VALIDATED: 'VALIDATED'
};

export const NO_ACCESS = ['WAITING_APPROVAL'];

export const NO_ACCESS_REJECTED = ['REJECTED'];

export const RESTRICTED_ACCESS = ['CONFIRMED', 'PROFILE_REQUESTED', 'REQUEST_DATA'];

export const FULL_ACCESS = ['VALIDATED'];

export const FULL_ACCESS_SALARYCHECK = ['VALIDATED', 'REQUEST_DATA'];

export const VERIFIED_SEAFARER_STATUSES = [
  'READY_FOR_MATCH',
  'READY_FOR_MATCH_REVIEW',
  'HIRED',
  'HIRED_REVIEW'
];

export const VISITED_SEAFARER_STATUSES = {
  VIEWED: 'VIEWED',
  NOT_VIEWED: 'NOT_VIEWED',
  UPDATED: 'UPDATED'
};
