import React, { createContext } from 'react';
import useModal from '../core/hooks/useModal';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const { modal, handleModal, modalContent, size, setSize } = useModal();
  return (
    <ModalContext.Provider
      value={{ modal, handleModal, modalContent, size, setSize }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
