// @flow
import { FeatureFlagsActionTypes } from './constants';

export const changeSearchMode = (mode) => ({
  type: FeatureFlagsActionTypes.CHANGE_SEARCH_MODE,
  payload: mode
});

export const changeSalaryCheckVisibility = (visibility) => ({
  type: FeatureFlagsActionTypes.CHANGE_SALARYCHECK_VISIBILITY,
  payload: visibility
});

export const changeFreeTextSearchVisibility = (visibility) => ({
  type: FeatureFlagsActionTypes.CHANGE_FREE_TEXT_SEARCH_VISIBILITY,
  payload: visibility
});
